<template>
  <!-- 主页 -->
  <div class="WikiDtl">
    <!-- 一 简介提要 -->
    <div class="synopsis">
      <!-- 包含图片、名字等 -->
      <div class="brief">
        <!-- 左边图片 -->
        <div class="brief_img">
          <img :src="arms.picture2" alt="" />
        </div>
        <!-- 右边文字信息 -->
        <div class="brief_text">
          <div class="brief_name" v-html="arms.name"></div>
          <div class="brief_nameBottom" v-html="arms.desc"></div>
          <div class="brief_word">
            <div
              class="word"
              v-if="!!arms.mimicryRole"
              v-html="'拟态角色：' + arms.mimicryRole"
            ></div>
            <div
              class="word"
              v-if="!!arms.profession"
              v-html="'职业：' + arms.profession"
            ></div>
          </div>
          <div class="brief_word">
            <div
              class="word"
              v-if="!!arms.position"
              v-html="'定位：' + arms.position"
            ></div>
            <div
              class="word"
              v-if="!!arms.element"
              v-html="'元素：' + arms.element"
            ></div>
          </div>
        </div>
      </div>
      <!-- 特征 -->
      <div class="features" v-if="!!arms.featureBias">
        <div class="features_title" style="white-space: nowrap">特性偏向：</div>
        <div class="features_title" v-html="arms.featureBias"></div>
      </div>
    </div>
    <!-- 二 详情数据 -->
    <div class="details">
      <!-- 1 -->
      <div class="details_content" v-if="!!arms.skill">
        <div class="details_left">技能</div>
        <div class="details_right" v-html="arms.skill"></div>
      </div>
      <!-- 2 -->
      <div class="details_content" v-if="!!arms.involvedSkill">
        <div class="details_left">
          连携 <br />
          技能
        </div>
        <div class="details_right" v-html="arms.involvedSkill"></div>
      </div>
      <!-- 3 -->
      <div class="details_content" v-if="!!arms.feature">
        <div class="details_left">特质</div>
        <div class="details_right" v-html="arms.feature"></div>
      </div>
      <!-- 1 -->
      <div class="details_content" v-if="!!arms.passive1">
        <div class="details_left">被动1</div>
        <div class="details_right" v-html="arms.passive1"></div>
      </div>
      <!-- 1 -->
      <div class="details_content" v-if="!!arms.passive2">
        <div class="details_left">被动2</div>
        <div class="details_right" v-html="arms.passive2"></div>
      </div>
      <!-- 1 -->
      <div class="details_content" v-if="!!arms.passive3">
        <div class="details_left">被动3</div>
        <div class="details_right" v-html="arms.passive3"></div>
      </div>
      <!-- 1 -->
      <div class="details_content" v-if="!!arms.passive4">
        <div class="details_left">被动4</div>
        <div class="details_right" v-html="arms.passive4"></div>
      </div>
      <!-- 1 -->
      <div class="details_content" v-if="!!arms.passive5">
        <div class="details_left">被动5</div>
        <div class="details_right" v-html="arms.passive5"></div>
      </div>
      <!-- 1 -->
      <div class="details_content" v-if="!!arms.passive6">
        <div class="details_left">被动6</div>
        <div class="details_right" v-html="arms.passive6"></div>
      </div>
    </div>
  </div>
</template>
<script>
import request from "../../util/request";
export default {
  name: "WikiDtl",
  components: {},
  data() {
    return {
      arms: {},
    };
  },
  methods: {},
  // async mounted() {
  //   this.arms = (await request.post("armsDetails", { id: this.$route.query.id })).data
  // },
  activated() {
    this.arms = {};
    request.post("armsDetails", { id: this.$route.query.id }).then((e) => {
      this.arms = e.data;
    });
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/less/public.less";
.WikiDtl {
  .el_attr_val(margin-top,10);
  .el_attr_val(margin-left,10);
  .el_attr_val(margin-right,10);
  .el_attr_val(padding,20);
  .el_attr_val(padding-bottom,30);
  .el_attr_val(border-radius,25);
  background-color: #fff;
  box-shadow: 0 0 10px rgb(90, 90, 90);
  color: #787878;
  // font-family: "SourceHanSansCN_Regular";
}
// 一 简介提要
.synopsis {
  // 包含图片、名字等
  .brief {
    display: flex;
    align-items: center;
    // 左边图片
    .brief_img {
      .el_attr_val(width,178);
      .el_attr_val(border-radius,10);
      .el_attr_val(padding,5);
      border: 0.5vw solid #eeeeee;
      display: flex;
    }
    // 右边文字信息
    .brief_text {
      .el_attr_val(margin-left,30);
      flex: 1;
      .brief_name {
        .el_attr_val(font-size,48);
        // font-family: "SourceHanSansCN_Medium";
        font-weight: bold;
        color: #f38f3f;
      }
      .brief_nameBottom {
        .el_attr_val(font-size,18);
        .el_attr_val(margin-top,10);
        .el_attr_val(padding-bottom,20);
      }
      .brief_word {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .el_attr_val(font-size,24);
        .el_attr_val(margin-top,10);

        .word {
          min-width: 48%;
          .el_attr_val(padding-left,15);
          .el_attr_val(padding-right,15);
          .el_attr_val(padding-top,5);
          .el_attr_val(padding-bottom,5);
          white-space: nowrap;
          background: #f5ede4;
        }
      }
    }
  }
  // 特征
  .features {
    display: flex;
    background: #f5ede4;
    .el_attr_val(margin-top,28);
    .el_attr_val(padding-top,8);
    .el_attr_val(padding-bottom,8);
    .el_attr_val(font-size,24);
    .features_title {
      .el_attr_val(margin-left,15);
    }
  }
}
// 二 详情数据
.details {
  .el_attr_val(margin-top,25);
  .details_content {
    // .el_attr_val(padding,20);
    .el_attr_val(font-size,24);
    background: #f5ede4;
    display: flex;
    align-items: center;
    border: 0.5vw solid #fff;
    .details_left {
      // font-family: "SourceHanSansCN_Medium";
      // font-weight: bold;
      // .el_attr_val(padding-left,20);
      // .el_attr_val(padding-right,20);
      .el_attr_val(width,88);
      white-space: nowrap;
      flex: none;
      text-align: center;
    }
    .details_right {
      border-left: 0.5vw solid #fff;
      .el_attr_val(padding,25);
      .el_attr_val(padding-top,15);
      .el_attr_val(padding-bottom,15);
    }
  }
}
</style>