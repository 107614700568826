import axios from 'axios'; 
import { Toast } from 'vant'
import 'vant/lib/toast/style';



//请求的基础地址


let _Toast = null;


/**
 * 请求类
 */
class HttpRequest {

    constructor(baseUrl = baseURL) {
        //请求基础地址
        this.baseUrl = baseUrl;
        //请求队列，其实更像一个缓存
        this.queue = {};
        //axios实例
        this.axiosInstance = axios.create({
            baseURL: this.baseUrl,
            withCredentials: true,
            headers: {
                //
            }
        });
        this.interceptors(this.axiosInstance, this.axiosInstance.defaults.url);
    }

    /**
     * 移除队列
     * @param {String} url 请求地址
     */
    dequeue(url) {

        Reflect.deleteProperty(this.queue, url);

        if (!Object.keys(this.queue).length && _Toast) {
            _Toast.clear();
            _Toast = null;
        }
    };

    /**
     * 创建拦截器
     * @param {Object} instance axios 实例
     * @param {String} url 请求地址
     */
    interceptors(instance, url) {

        // 请求拦截
        instance.interceptors.request.use(config => {

            // 添加全局的loading...
            if (!Object.keys(this.queue).length && _Toast === null && config.loading !== false) {
                _Toast = Toast.loading({
                    duration: 0,
                    forbidClick: true,
                    message: '加载中...',
                });

            };

            this.queue[url] = true;

            return config;
        }, error => Promise.reject(error));

        // 响应拦截
        instance.interceptors.response.use(res => {

            let { data } = res;

            this.dequeue(url);


            return data || { errMsg: "接口无任何返回", code: -1 };
        }, error => {

            this.dequeue(url);

            let _errorInfo = error.response;

            if (!_errorInfo) {

                const {
                    request: { statusText, status },
                    config
                } = JSON.parse(JSON.stringify(error));

                _errorInfo = {
                    statusText,
                    status,
                    request: {
                        responseURL: config.url
                    }
                }
            };

            //此处可以记录错误日志
            return Promise.reject(error);
        })
    }

    /**
     * 发送post请求
     * @param {*} url 
     * @param {*} params 
     * @param {*} options 
     */
    post(url, params, options) {

        return this.axiosInstance.post(url, params, options).then(data => {

            if (data.state != 0) {
                return Promise.reject(Object.assign({ message: data.data.message }, data))
            };

            return Promise.resolve(data);
        });
    }
};

let url = process.env.NODE_ENV === "development" ? "/api/" : location.origin + "/api/"
export default new HttpRequest(url);