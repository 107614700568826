<template>
  <!-- 大地图 -->
  <div class="map">
    <!-- 一 返回首页 -->
    <!-- <div class="backHome" @click="toPage('Home')">点击这里返回首页</div> -->
    <!-- 二 下拉列表 -->
    <div class="map_list">
      <!-- 1地标 -->
      <!-- <div class="list"> -->
      <!-- 上面的标题 -->
      <!-- <div class="title">
          <div class="icon">
            <img src="../assets/img/Map/arrow.png" alt="" />
          </div>
          <div class="text">地标</div>
        </div>
      </div> -->
      <!-- 2宝箱 -->
      <div class="list" v-for="item in classMap" :key="item.id">
        <!-- 上面的标题 -->
        <div class="title" @click="item.listShow = !item.listShow">
          <div class="icon" :class="item.listShow ? 'icon_select' : ''">
            <img src="../assets/img/Map/arrow.png" alt="" />
          </div>
          <div class="text" v-html="item.mapName"></div>
        </div>
        <!-- 下面的展开部分 -->
        <div class="list_show" v-if="item.listShow">
          <!-- <div class="list_showImg">
            <img src="../assets/img/Map/list_showImg1.jpg" alt="" />
          </div> -->
          <!-- 加上list_showText-select为选中 -->
          <template v-for="item1 in pointType">
            <template v-if="item1.mapClassId == item.id">
              <!-- <template v-for="item2 in item1.child"> -->
              <div
                :key="item1.id"
                class="list_showText"
                :class="{ 'list_showText-select': item1.isSelect }"
                v-html="item1.resourceName"
                @click="clickTypePoing(item1)"
              ></div>
              <!-- </template> -->
            </template>
          </template>
          <!-- <div class="list_showText">普通宝箱</div> -->
        </div>
      </div>
      <!-- 3食材 -->
      <!-- <div class="list">
        <div class="title last_list">
          <div class="icon">
            <img src="../assets/img/Map/arrow.png" alt="" />
          </div>
          <div class="text">食材</div>
        </div>
      </div> -->
    </div>
    <!-- 三 地图图片 -->
    <div class="map_img" ref="map">
      <!-- <img src="../assets/img/Map/map-img.jpg" alt="" /> -->
    </div>
  </div>
</template>
<script>
import request from "../util/request";

export default {
  name: "About",
  data() {
    return {
      classMap: [],
      pointType: [], //物品类型
      map: {}, //地图对象
      pointObj: {}, //记录添加进地图的点
    };
  },
  methods: {
    clickTypePoing(item1, item2) {
      item1.isSelect = !item1.isSelect;
      if (item1.isSelect) this.addPoint(item1);
      else this.removePoint(item1.id);
    },
    addPoint(opt) {
      this.pointObj[opt.id] = [];

      // Adding marker to the map
      for (let i = 0; i < opt.child.length; i++) {
        let tempChile = opt.child[i];
        // console.log(tempChile.x / 100, tempChile.y / 100)
        //x 和 y 要反着来 传入地图的是经纬度
        //需要转换换算一下
        let temp = L.marker([tempChile.x, tempChile.y], {
          // let temp = L.marker([0, 20], {
          icon: L.icon({
            iconUrl: opt.icon,
            iconAnchor: [14.5, 46],
            iconSize: [29, 46],
            popupAnchor: [0, -40],
            // id: 232,
            typeName: tempChile.caption,
          }),
          title: tempChile.caption,
        }).addTo(this.map);

        temp.bindPopup().on("popupopen", (e) => {
          document.querySelectorAll(".leaflet-popup-content")[0].innerHTML =
            e.sourceTarget.options.title;
        });

        this.pointObj[opt.id].push(temp);
      }
    },
    removePoint(id) {
      this.pointObj[id].forEach((e) => {
        e.remove();
      });
    },
  },
  created() {
    request
      .post("classificationTree")
      .then((e) => {
        e.data.forEach((e) => {
          e.listShow = false;
        });
        this.classMap = e.data;
        return request.post("classResource");
      })
      .then((e) => {
        this.pointType = e.data;
      });
  },
  mounted() {
    // 每次窗口大小改变后调用这个方法
    // map.invalidateSize(true);
    this.$nextTick((e) => {
      let mapOptions = {
        center: [0, 0],
        zoom: 5,
        maxZoom: 9,
        minZoom: 5,
        // maxBounds: [[86, -140], [-86, 140]],
        maxBounds: [
          [20, -20],
          [-20, 20],
        ],
        zoomControl: false,
        attributionControl: false,
        //修改计算坐标体系值
        crs: new L.Proj.CRS(
          "EPSG:900913",
          "+proj=merc +a=6378206 +b=6356584.314245179 +lat_ts=0.0 +lon_0=0.0 +x_0=0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext	+no_defs",
          {
            resolutions: (function () {
              let level = 19,
                res = [];
              for (let i = 0; i < level; i++) {
                res[i] = Math.pow(2, 18 - i);
              }
              return res;
            })(),
            origin: [0, 0],
          }
        ),
      };

      //重写获取坐标方法
      let tileLayerCustom = L.TileLayer.extend({
        getTileUrl: function (coords) {
          coords = (function (coords) {
            let y = -coords.y + -1,
              r = Math.ceil((1 << (coords.z - 1)) / 2);
            return (
              -r / 2 <= coords.x &&
              coords.x < r &&
              -r <= y &&
              y <= r / 2 - 1 && { x: coords.x, y: -coords.y + -1, z: coords.z }
            );
          })(coords);
          return coords
            ? `./mapImg/${coords.z}/tile-${coords.x}_${coords.y}.png`
            : `./mapImg/def.png`;
        },
      });

      //初始化地图
      this.map = L.map(this.$refs.map, mapOptions);
      new tileLayerCustom().addTo(this.map);

      console.log(this.map.getPixelBounds());
      setTimeout(() => {
        console.log(this.map.getPixelBounds(), this.map.getPixelWorldBounds());
      }, 3000);
      this.map.on("pm:create", function (e) {
        let layer = e.layer;
        console.log(e);
      });

      var mypop = L.popup();

      this.map.on("click", function (e) {
        // console.log(e.latlng);
      });

      // window.cc = this.map;
    });
  },
};
</script>
<style lang="less" scoped>
@import "../assets/less/public.less";
.map {
  .el_attr_val(margin-top,10);
  .el_attr_val(margin-left,10);
  .el_attr_val(margin-right,10);
  // font-family: "SourceHanSansCN_Medium";
}
// 一 返回首页
.backHome {
  .el_attr_val(font-size,26.7);
  color: #3e3e3e;
  font-weight: bold;

  .el_attr_val(padding-top,25);
  .el_attr_val(padding-left,25);
  .el_attr_val(padding-bottom,30);
  .el_attr_val(border-radius,30);
  box-shadow: 0 0 10px rgb(179, 178, 178);
}
// 二 下拉列表
.map_list {
  .el_attr_val(border-radius,25);
  box-shadow: 0 0 10px rgb(179, 178, 178);
  background-color: #fff;
  overflow: hidden;
  // .el_attr_val(margin-top,25);
  .list {
    // 上面的标题
    .title {
      font-weight: bold;
      .el_attr_val(font-size,30);
      .el_attr_val(padding-top,21);
      .el_attr_val(padding-left,25);
      .el_attr_val(padding-bottom,21);
      border-bottom: 0.5vw solid #e6e4e4;
      .icon {
        display: inline-block;
        .el_attr_val(width,13);
        // 点击后改变箭头方向
        &.icon_select {
          transform: rotate(90deg);
        }
      }
      .text {
        display: inline-block;
        .el_attr_val(margin-left,28);
      }
      // 最后一个去掉下划线
      // &:last-child {
      //   border: none;
      // }
    }
    // 下面的展开部分
    .list_show {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .el_attr_val(margin-top,10);
      .el_attr_val(margin-bottom,10);
      .el_attr_val(padding-bottom,10);
      animation: listShowAn 0.5s;
      .list_showImg {
        .el_attr_val(margin-left,57);
        .el_attr_val(width,67);
      }
      .list_showText {
        .el_attr_val(margin-left,63);
        .el_attr_val(padding-left,20);
        .el_attr_val(padding-right,20);
        .el_attr_val(padding-top,10);
        .el_attr_val(padding-bottom,10);
        .el_attr_val(border-radius,30);
        .el_attr_val(font-size,30);
        font-weight: bold;
        // font-family: "SourceHanSansCN_Regular";
        &.list_showText-select {
          background: #f0f0f0;
        }
      }
    }
    @keyframes listShowAn {
      from {
        transform: translateX(-2vw);
      }
      to {
        transform: translateX(0);
      }
    }
  }
}
// 三 地图图片
.map_img {
  width: 100%;
  height: 120vw;
  .el_attr_val(margin-top,24);
  overflow: hidden;
  font-size: none;
  .el_attr_val(border-radius,30);
  background: #214260;
}
</style>