<template>
    <!-- 分类页 -->
    <div class="classification">
        <!-- 一 基础资料 -->
        <div class="wikiContent">
            <!-- 标题背景图 -->
            <div class="title_bg">
                <img src="../assets/img/classification/bgclass.png" alt="" />
            </div>
            <!-- 标题 -->
            <div
                class="title"
                @click="
                    pageList.initialValue.detailShow = !pageList.initialValue
                        .detailShow
                "
            >
                <p class="text">基础资料</p>
                <!-- 这里加上title_iconSelect类名改变方向 -->
                <div
                    class="title_icon"
                    :class="
                        pageList.initialValue.detailShow
                            ? 'title_iconSelect'
                            : ''
                    "
                >
                    <img src="../assets/img/classification/arrow.png" alt="" />
                </div>
            </div>
            <div class="backgroundWhite">
                <!-- 内容详情 -->
                <div class="details" v-if="pageList.initialValue.detailShow">
                    <!-- 1 -->
                    <div
                        class="details_content"
                        v-for="item in pageList.initialValue.data"
                        :key="item.id"
                        @click="
                            toPage(item.page, { id: item.id, key: item.key })
                        "
                    >
                        <div class="details_img">
                            <img
                                :src="item.src"
                                alt=""
                            />
                        </div>
                        <div class="details_name">{{ item.name }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 二 战斗相关 -->
        <div class="wikiContent">
            <!-- 标题背景图 -->
            <div class="title_bg">
                <img src="../assets/img/classification/bgclass.png" alt="" />
            </div>
            <!-- 标题 -->
            <div
                class="title"
                @click="
                    pageList.combotRelated.detailShow = !pageList.combotRelated
                        .detailShow
                "
            >
                <p class="text">战斗相关</p>
                <!-- 这里加上title_iconSelect类名改变方向 -->
                <div
                    class="title_icon"
                    :class="
                        pageList.combotRelated.detailShow
                            ? 'title_iconSelect'
                            : ''
                    "
                >
                    <img src="../assets/img/classification/arrow.png" alt="" />
                </div>
            </div>
            <div class="backgroundWhite">
                <!-- 内容详情 -->

                <div class="details" v-if="pageList.combotRelated.detailShow">
                    <!-- 1 -->
                    <div
                        class="details_content"
                        v-for="item2 in pageList.combotRelated.data"
                        :key="item2.id"
                        @click="
                            toPage(item2.page, { id: item2.id, key: item2.key })
                        "
                    >
                        <div class="details_img">
                            <img
                                :src="item2.src"
                                alt=""
                            />
                        </div>
                        <div class="details_name">{{ item2.name }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 三 任务 -->
        <div class="wikiContent">
            <!-- 标题背景图 -->
            <div class="title_bg">
                <img src="../assets/img/classification/bgclass.png" alt="" />
            </div>
            <!-- 标题 -->
            <div
                class="title"
                @click="pageList.task.detailShow = !pageList.task.detailShow"
            >
                <p class="text">任务</p>
                <!-- 这里加上title_iconSelect类名改变方向 -->
                <div
                    class="title_icon"
                    :class="pageList.task.detailShow ? 'title_iconSelect' : ''"
                >
                    <img src="../assets/img/classification/arrow.png" alt="" />
                </div>
            </div>
            <div class="backgroundWhite">
                <!-- 内容详情 -->
                <div class="details" v-if="pageList.task.detailShow">
                    <div
                        class="details_content"
                        v-for="item3 in pageList.task.data"
                        :key="item3.id"
                        @click="
                            toPage(item3.page, { id: item3.id, key: item3.key })
                        "
                    >
                        <div class="details_img">
                            <img
                                :src="item3.src"
                                alt=""
                            />
                        </div>
                        <div class="details_name">{{ item3.name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import util from "../util/util";
import request from "../util/request";

export default {
    name: "Classification",
    components: {},
    data() {
        return {
            pageList: {
                initialValue: {
                    detailShow: false,
                    data: [
                        // detailShow: true,
                        //
                        // { name: "人物属性", data: [], key: "", page: "" },
                        {
                            name: "成就",
                            data: [],
                            key: "achievementList",
                            page: "Wiki",
                            src:require("../assets/img/classification/chengjiu.png")
                        },
                        // {
                        //     name: "好感度",
                        //     data: [],
                        //     key: "favorabilityList",
                        //     page: "Wiki"
                        // },
                        {
                            name: "武器",
                            data: [],
                            key: "armsList",
                            page: "Wiki",
                            src:require("../assets/img/classification/wuqi.png")
                        },
                        {
                            name: "源器",
                            data: [],
                            key: "sourceList",
                            page: "Wiki",
                            src:require("../assets/img/classification/yuqi.png")
                        },
                        {
                            name: "抑制器",
                            data: [],
                            key: "suppressorList",
                            page: "Wiki",
                            src:require("../assets/img/classification/yizhiqi.png")
                        },
                        {
                            name: "意志",
                            data: [],
                            key: "willList",
                            page: "Wiki",
                            src:require("../assets/img/classification/yizhi.png")
                        },
                        {
                            name: "道具",
                            data: [],
                            key: "thingList",
                            page: "Wiki",
                            src:require("../assets/img/classification/daoju.png")
                        },
                        {
                            name: "烹饪",
                            data: [],
                            key: "cookingList",
                            page: "Wiki",
                            src:require("../assets/img/classification/pengren.png")
                        },
                        {
                            name: "外观",
                            data: [],
                            key: "exteriorList",
                            page: "Wiki",
                            src:require("../assets/img/classification/waiguan.png")
                        },
                        {
                            name: "载具",
                            data: [],
                            key: "vehicleList",
                            page: "Wiki",
                            src:require("../assets/img/classification/zaiju.png")
                        },
                        {
                            name: "货币",
                            data: [],
                            key: "currencyList",
                            page: "Wiki",
                            src:require("../assets/img/classification/huobi.png")
                        },
                        // {
                        //     name: "艾达往事",
                        //     data: [],
                        //     key: "pastList",
                        //     page: "Wiki"
                        // },
                        {
                            name: "装备",
                            data: [],
                            key: "equipmentList",
                            page: "Wiki",
                            src:require("../assets/img/classification/zhuangbei.png")
                        }
                    ]
                },
                combotRelated: {
                    detailShow: false,
                    data: [
                        {
                            name: "连携攻击",
                            data: [],
                            key: "chainAttackList",
                            page: "Wiki",
                            src:require("../assets/img/classification/lianxiegongji.png")
                        },
                        // { name: "幻时", data: [], key: "", page: "" },
                        {
                            name: "元素",
                            data: [],
                            key: "elementList",
                            page: "Wiki",
                            src:require("../assets/img/classification/yuansu.png")
                        },
                        // {
                        //     name: "源器材料",
                        //     data: [],
                        //     key: "sourceMaterialList",
                        //     page: "Wiki"
                        // }
                        // {
                        //     name: "武器共鸣",
                        //     data: [],
                        //     key: "weaponMaterialList",
                        //     page: "Wiki"
                        // }
                    ]
                },
                task: {
                    detailShow: false,
                    data: [
                        {
                            name: "主线剧情",
                            data: [],
                            key: "mainStoryList",
                            page: "Wiki",
                            src:require("../assets/img/classification/zhuxianjuqing.png")
                        },
                        {
                            name: "支线任务",
                            data: [],
                            key: "sideTaskList",
                            page: "Wiki",
                            src:require("../assets/img/classification/zhixianrenwu.png")
                        },
                        {
                            name: "悬赏任务",
                            data: [],
                            key: "rewardTaskList",
                            page: "Wiki",
                            src:require("../assets/img/classification/xuanshangrenwu.png")
                        },
                        // { name: "书信任务", data: [], key: "", page: "" },
                        {
                            name: "工会任务",
                            data: [],
                            key: "unionList",
                            page: "Wiki",
                            src:require("../assets/img/classification/gonghuirenwu.png")
                        },
                        {
                            name: "日常任务",
                            data: [],
                            key: "dailyTasksList",
                            page: "Wiki",
                            src:require("../assets/img/classification/richangrenwu.png")
                        },
                        {
                            name: "引导任务",
                            data: [],
                            key: "guideTaskList",
                            page: "Wiki",
                            src:require("../assets/img/classification/yindaorenwu.png")
                        },
                        {
                            name: "家园任务",
                            data: [],
                            key: "homeTaskList",
                            page: "Wiki",
                            src:require("../assets/img/classification/jiayuanrenwu.png")
                        }
                    ]
                }
            }
        };
    },
    methods: {},
    mounted() {
        // request.post("getList").then(e => {
        //     // console.log(e);
        //     for (let i = 0; i < this.pageList.initialValue.length; i++) {
        //         this.pageList.initialValue.data[i].data =
        //             e.data[this.pageList.initialValue.data[i].key];
        //     }
        //     for (let i = 0; i < this.pageList.combotRelated.length; i++) {
        //         this.pageList.initialValue.data[i].data =
        //             e.data[this.pageList.initialValue.data[i].key];
        //     }
        //     for (let i = 0; i < this.pageList.task.length; i++) {
        //         this.pageList.initialValue.data[i].data =
        //             e.data[this.pageList.initialValue.data[i].key];
        //     }
        //     console.log(this.pageList);
        // });
        // 循环加载数据
        // for (let i = 0; i < this.pageList.length; i++) {
        //   request.post(this.pageList[i].itf).then((e) => {
        //     this.pageList[i].data = e.data;
        //   });
        // }
    }
};
</script>
<style lang="less" scoped>
@import "../assets/less/public.less";
.classification {
    .el_attr_val(margin-left, 10);
    .el_attr_val(margin-right, 10);
    // font-family: "SourceHanSansCN_Medium";
    padding-top:10px ;
}   
.wikiContent {
    .backgroundWhite {
        position: relative;
        top: -30px;
        width: 99.5%;
        margin: 0 auto;
        padding-top: 20px;
        background-color: #fff;
    }
    position: relative;
    // .el_attr_val(margin-bottom, 24);
    .el_attr_val(border-radius, 25);
    .el_attr_val(border-top-left-radius, 35);
    // background-color: #fff;
    // box-shadow: 0 0 10px rgb(117, 116, 116);
    .title_bg {
        font-size: 0;
        width: 100%;
        position: relative;
        z-index: 1;
    }
    // 标题
    .title {
        position: absolute;
        .el_attr_val(top, 20);
        // .el_attr_val(margin-left, 40);
        // .el_attr_val(margin-right, 40);
        left: 0;
        display: flex;
        z-index: 1;
        justify-content: space-between;
        width: 100%;
        // background: url('../assets/img/classification/bgclass.png') center no-repeat;
        // background-size: cover;
        // .rectangle {
        //   background: #e4c866;
        //   .el_attr_val(width, 8);
        //   .el_attr_val(height, 45);
        // }
        .text {
            .el_attr_val(text-indent, 53);
            line-height: 6vw;
            .el_attr_val(font-size, 45);
            // font-family: "SourceHanSansCN_Bold";
            white-space: nowrap;
            font-weight: bold;
        }
        .title_icon {
            .el_attr_val(margin-right, 50);
            .el_attr_val(width, 20);
            // transform: rotate(90deg);
            // 展开箭头样式
            &.title_iconSelect {
                transform: rotate(270deg);
                transition: 50% 50%
            }
        }
    }
    // 内容详情
    .details {
        .el_attr_val(padding, 40);
        .el_attr_val(padding-top, 0);
        .el_attr_val(max-height, 620);
        overflow: scroll;
        overflow-x: hidden;
        display: flex;
        flex-wrap: wrap;
        .details_content {
            display: flex;
            align-items: center;
            flex-direction: column;
            .el_attr_val(margin-left, 70);
            .el_attr_val(margin-top, 35);
            .el_attr_val(margin-bottom, 35);
            position: relative;
            &:nth-child(4n + 1) {
                margin-left: 0;
            }
            .details_img {
                .el_attr_val(width, 108);
                .el_attr_val(height, 120);
            }
            .details_name {
                // white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                // .el_attr_val(margin-top,7);
                .el_attr_val(font-size, 22.2);
                font-weight: bold;
                .el_attr_val(width, 110);
                text-align: center;
                position: absolute;
                top: 110%;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
    }
}
</style>
