import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    toPage(name, query = {}, type = 'push') {

      if (query == "") query = {}

      this.$router[type]({ name, query });
    }
  },
  data() {
    return {
      // channel: util.GetQueryString("channel")
    };
  }
});

new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app')
