<template>
  <!-- 主页 -->
  <div class="WikiDtl">
    <!-- 一 简介提要 -->
    <div class="synopsis">
      <!-- 包含图片、名字等 -->
      <div class="brief">
        <!-- 左边图片 -->
        <div class="brief_img">
          <img :src="arms.picture2" alt="" />
        </div>
        <!-- 右边文字信息 -->
        <div class="brief_text">
          <div class="brief_name" v-html="arms.name"></div>
          <!-- <div class="brief_nameBottom" v-html="arms.desc"></div> -->
          <div class="brief_word">
            <div
              class="word"
              v-if="!!arms.class"
              v-html="'分类：' + arms.class"
            ></div>
            <!-- <div class="word" v-if="!!arms.element" v-html="'元素：'+arms.element"></div> -->
          </div>
          <div class="brief_word">
            <div
              class="word"
              v-if="!!arms.use"
              v-html="'用途：' + arms.use"
            ></div>
            <!-- <div class="word" v-if="!!arms.element" v-html="'元素：'+arms.element"></div> -->
          </div>
        </div>
      </div>
      <!-- 特征 -->
      <!-- <div class="features" v-if="!!arms.featureBias">
        <div class="features_title" style="white-space: nowrap">特性偏向：</div>
        <div class="features_title" v-html="arms.featureBias"> </div>
      </div> -->
    </div>
    <!-- 二 详情数据 -->
    <div class="details">
      <!-- 1 -->
      <div class="details_content" v-if="!!arms.goodsDesc">
        <div class="details_left">物品<br />描述</div>
        <div class="details_right" v-html="arms.goodsDesc"></div>
      </div>
      <div class="details_content" v-if="!!arms.goodsUse">
        <div class="details_left">物品<br />用途</div>
        <div class="details_right" v-html="arms.goodsUse"></div>
      </div>
      <div class="details_content" v-if="!!arms.gainDesc">
        <div class="details_left">获取<br />方式</div>
        <div class="details_right" v-html="arms.gainDesc"></div>
      </div>
      <!-- 星级循环 -->
      <!-- <template v-for="(i) in 7">
        <div :key="i" class="details_content" v-if="!!arms['star'+(i+1)]">
          <div class="details_left">
            {{(i+1)}}星 <br />
            激活
          </div>
          <div class="details_right" v-html="arms['star'+(i+1)]"> </div>
        </div>
      </template> -->
    </div>
  </div>
</template>
<script>
import request from "../../util/request";
export default {
  name: "Cooking",
  components: {},
  data() {
    return {
      arms: {},
    };
  },
  methods: {},
  // async mounted() {
  //   this.arms = (await request.post("armsDetails", { id: this.$route.query.id })).data
  // },
  activated() {
    this.arms = {};
    request.post("goodsDetails", { id: this.$route.query.id }).then((e) => {
      this.arms = e.data;
    });
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/less/public.less";
.WikiDtl {
  .el_attr_val(margin-top,10);
  .el_attr_val(margin-left,10);
  .el_attr_val(margin-right,10);
  .el_attr_val(padding,20);
  .el_attr_val(padding-bottom,30);
  .el_attr_val(border-radius,25);
  background-color: #fff;
  box-shadow: 0 0 10px rgb(90, 90, 90);
  color: #787878;
  // font-family: "SourceHanSansCN_Regular";
}
// 一 简介提要
.synopsis {
  // 包含图片、名字等
  .brief {
    display: flex;
    align-items: center;
    // 左边图片
    .brief_img {
      .el_attr_val(width,178);
      .el_attr_val(border-radius,10);
      .el_attr_val(padding,5);
      border: 0.5vw solid #eeeeee;
      display: flex;
    }
    // 右边文字信息
    .brief_text {
      .el_attr_val(margin-left,30);
      flex: 1;
      .brief_name {
        .el_attr_val(font-size,48);
        // font-family: "SourceHanSansCN_Medium";
        font-weight: bold;
        color: #69abe8;
      }
      .brief_nameBottom {
        .el_attr_val(font-size,18);
        .el_attr_val(margin-top,10);
        .el_attr_val(padding-bottom,20);
      }
      .brief_word {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .el_attr_val(font-size,24);
        .el_attr_val(margin-top,10);

        .word {
          min-width: 48%;
          .el_attr_val(padding-left,15);
          .el_attr_val(padding-right,15);
          .el_attr_val(padding-top,0);
          .el_attr_val(padding-bottom,0);
          white-space: nowrap;
          background: #e8f1f7;
        }
      }
    }
  }
  // 特征
  .features {
    display: flex;
    background: #faf0f0;
    .el_attr_val(margin-top,28);
    .el_attr_val(padding-top,8);
    .el_attr_val(padding-bottom,8);
    .el_attr_val(font-size,24);
    .features_title {
      .el_attr_val(margin-left,15);
    }
  }
}
// 二 详情数据
.details {
  .el_attr_val(margin-top,25);
  .details_content {
    // .el_attr_val(padding,20);
    .el_attr_val(font-size,24);
    background: #e8f1f7;
    display: flex;
    align-items: center;
    border: 0.5vw solid #fff;
    .details_left {
      // font-family: "SourceHanSansCN_Medium";
      // font-weight: bold;
      // .el_attr_val(padding-left,20);
      // .el_attr_val(padding-right,20);
      .el_attr_val(width,88);
      white-space: nowrap;
      flex: none;
      text-align: center;
    }
    .details_right {
      border-left: 0.5vw solid #fff;
      .el_attr_val(padding,25);
      .el_attr_val(padding-top,15);
      .el_attr_val(padding-bottom,15);
    }
  }
}
</style>