<template>
  <!-- 捏脸列表 -->
  <div class="Face">
    <div class="Face_content">
      <!-- 一 搜索 -->
      <div class="Face_search">
        <!-- 放大镜部分 -->
        <div class="magnifier">
          <div class="icon">
            <img src="../assets/img/Face/magnifier.png" alt="" />
          </div>
          <input
            v-model="inputVal"
            class="text"
            type="text"
            placeholder="搜索"
            name=""
            id=""
          />
        </div>
        <!-- 箭头 -->
        <div class="arrow">
          <img src="../assets/img/Face/arrow.png" alt="" />
        </div>
      </div>
      <!-- 二 分类 -->
      <div class="face_classification">
        <!-- 选中样式class_textSelect -->
        <template v-for="item in pageList">
          <div
            :key="item.name"
            @click="selectType(item)"
            class="class_text"
            :class="{ class_textSelect: item.select }"
          >
            {{ item.name }}
          </div>
        </template>

        <!-- <div class="class_text">男</div>
          <div class="class_text">女</div> -->
      </div>
      <!-- 三 详情 -->
      <div class="details">
        <!-- 1 -->
        <div class="details_content" v-for="item in getData" :key="item.id" @click=" toPage('FaceDtl', { id: item.id})">
          <div class="details_img">
            <img :src="item.picture" alt="" />
            <!-- <img src="../assets/img/Face/img.png" alt="" /> -->
          </div>
          <div class="details_name" v-html="item.name"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from "../util/request";

export default {
  name: "Face",
  components: {},
  computed: {
    getData() {
      let temp = this.data;

      let typeName = this.pageList.filter((e) => e.select)[0].type;
      if (typeName == 1) temp = temp.filter((e => e.bodyType == 1));
      if (typeName == 0) temp = temp.filter((e => e.bodyType == 0));

      let searchVal = this.inputVal.replace(/ /g, "");
      if (searchVal.length != 0)
        temp = temp.filter((e => e.name.indexOf(searchVal) > -1));

      return temp;
    },
  },
  data() {
    return {
      //要跳转走的页面配置
      pageList: [
        {
          name: "全部",
          select: true,
        },
        {
          name: "男",
          select: false,
          type: 1,
        },
        {
          name: "女",
          select: false,
          type: 0,
        },
      ],
      inputVal: "", //搜索值
      data: [], //获取的接口数据
    };
  },
  methods: {
    selectType(item) {
      this.pageList.forEach((element) => {
        if (item.name == element.name) {
          item.select = true;
        } else {
          element.select = false;
        }
      });
    },
  },
  mounted() {
    request.post("figureModelList").then((e) => {
      console.log(e);
      this.data = e.data;
      // for (let i = 0; i < this.pageList.length; i++) {
      //   this.pageList[i].data = e.data[this.pageList[i].key];
      // }
    });

    // findPageItem = pageList
    //循环加载数据
    // for (let i = 0; i < this.pageList.length; i++) {
    //   request.post(this.pageList[i].itf).then((e) => {
    //     this.pageList[i].data = e.data;
    //   });
    // }
  },
};
</script>
<style lang="less" scoped>
@import "../assets/less/public.less";
.Face_content {
  .el_attr_val(margin-top,10);
  .el_attr_val(margin-left,10);
  .el_attr_val(margin-right,10);
  .el_attr_val(padding,40);
  .el_attr_val(padding-top,20);
  background-color: #fff;
  // font-family: "SourceHanSansCN_Medium";
}
// 一 搜索
.Face_search {
  .el_attr_val(padding,25);
  .el_attr_val(padding-left,20);
  .el_attr_val(padding-right,20);
  .el_attr_val(border-radius,50);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f4f4f4;
  // 放大镜
  .magnifier {
    display: flex;
    align-items: center;
    .icon {
      .el_attr_val(width,42);
    }
    .text {
      border: none;
      background: none;
      .el_attr_val(margin-left,17);
      .el_attr_val(font-size,32);
      .el_attr_val(height,40);
    }
    .text::placeholder {
      .el_attr_val(font-size,30);
      color: #c3c3c3;
    }
  }
  // 箭头
  .arrow {
    .el_attr_val(width,24);
  }
}
// 二 分类
.face_classification {
  display: flex;
  .el_attr_val(margin-top,40);
  .el_attr_val(width,357);
  .el_attr_val(border-radius,50);
  background-color: #f0f0f0;
  .class_text {
    .el_attr_val(width,125);
    .el_attr_val(border-radius,50);
    text-align: center;
  }
  // 选中样式
  .class_textSelect {
    background-color: #a9c9ff;
  }
}
// 三 详情
.details {
  // .el_attr_val(max-height, 600);
  // height: 100vh;
  // overflow: scroll;
  // overflow-x: hidden;
  display: flex;
  height: auto;
  flex-wrap: wrap;
  .details_content {
    display: flex;
    align-items: center;
    flex-direction: column;
    .el_attr_val(margin-left, 35);
    .el_attr_val(margin-top, 35);
    .el_attr_val(margin-bottom, 35);
    position: relative;
    &:nth-child(4n + 1) {
      margin-left: 0;
    }
    .details_img {
      .el_attr_val(width, 135);
      .el_attr_val(height, 135);
      .el_attr_val(padding, 10);
      background: url('../assets/img/Face/img-boder.png') no-repeat center;
      background-size: 100%;
      font-size: 0;
      img{
        height: 100%;
      }
    }
    .details_name {
      // white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      // .el_attr_val(margin-top,7);
      .el_attr_val(font-size, 22.2);
      font-weight: bold;
      .el_attr_val(width, 110);
      text-align: center;
      position: absolute;
      top: 110%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
</style>