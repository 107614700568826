<template>
  <!-- 捏脸详情 -->
  <div class="FaceDtl">
    <div class="FaceDtl_content">
      <!-- 一 图片 -->
      <div class="FaceDtl_img">
        <!-- <img src="../assets/img/FaceDtl/face-img.jpg" alt="" /> -->
        <img :src="arms.picture2" alt="" />
      </div>
      <!-- 二 简介 -->
      <div class="details">
        <!-- 1 -->
        <div class="details_content" v-if="!!arms.imageId">
          <div class="details_left">形象ID</div>
          <div class="details_right" v-html="arms.imageId"></div>
        </div>
        <div class="details_content">
          <div class="details_left">体型</div>
          <div
            class="details_right"
            v-html="arms.bodyType == 0 ? '女' : '男'"
          ></div>
        </div>
        <div class="details_content"  v-if="!!arms.instructions">
          <div class="details_left">使用方法</div>
          <div class="details_right" v-html="arms.instructions"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from "../util/request";

export default {
  name: "FaceDtl",
  components: {},
  data() {
    return {
      arms: {},
    };
  },
  methods: {},
  activated() {
    this.arms = {};
    request
      .post("figureModelDetails", { id: this.$route.query.id })
      .then((e) => {
        this.arms = e.data;
      });
  },
};
</script>
<style lang="less" scoped>
@import "../assets/less/public.less";
.FaceDtl_content {
  .el_attr_val(margin-top,10);
  .el_attr_val(margin-left,10);
  .el_attr_val(margin-right,10);
  .el_attr_val(padding-bottom,40);
  // font-family: "SourceHanSansCN_Medium";
  background: #fff;

}
// 一 图片
.FaceDtl_img {
  width: 100%;
  font-size: 0;
}
// 二 简介
.details {
  .el_attr_val(margin-top,25);
  .el_attr_val(margin-left,30);
  .el_attr_val(margin-right,30);
  .details_content {
    // .el_attr_val(padding,20);
    .el_attr_val(font-size,24);
    background: #e8f1f7;
    display: flex;
    align-items: center;
    border: 0.5vw solid #fff;
    color: #787878;
    .details_left {
      // font-family: "SourceHanSansCN_Medium";
      // font-weight: bold;
      // .el_attr_val(padding-left,20);
      // .el_attr_val(padding-right,20);
      .el_attr_val(width,130);
      white-space: nowrap;
      flex: none;
      text-align: center;
    }
    .details_right {
      border-left: 0.5vw solid #fff;
      .el_attr_val(padding,25);
      .el_attr_val(padding-top,15);
      .el_attr_val(padding-bottom,15);
    }
  }
}
</style>