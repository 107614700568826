<template>
  <!-- 主页 -->
  <div class="Wiki">
    <!-- 一 武器 -->
    <template v-for="item in pageList">
      <div class="wikiContent" v-if="key == item.key" :key="item.page">
        <!-- 标题 -->
        <div class="title">
          <!-- <div class="title" @click="item.detailShow = !item.detailShow"> -->
          <!-- 左边长方形 -->
          <!-- <p class="rectangle"></p> -->
          <p class="text rectangle">{{ item.name }}</p>
          <!-- 这里加上title_iconSelect类名改变方向 -->
          <div
            class="title_icon"
            :class="item.detailShow ? 'title_iconSelect' : ''"
          >
            <!-- <img src="../assets/img/Wiki/title_icon.png" alt="" /> -->
          </div>
        </div>
        <!-- 内容详情 -->
        <div class="details" v-if="item.detailShow">
          <!-- 1 -->
          <div
            v-for="item1 in item.data"
            :key="item1.id"
            @click="toPage(item.page, { id: item1.id, key: item.key })"
            class="details_content"
          >
            <div class="details_img">
              <img :src="item1.picture" alt="" />
              <!-- <img src="../assets/img/Wiki/arms-1.png" alt="" /> -->
            </div>
            <div class="details_name">{{ item1.name }}</div>
          </div>
        </div>
      </div>
    </template>

    <!-- 二 源器 -->
    <div v-if="0" class="wikiContent">
      <!-- 标题 -->
      <div class="title">
        <!-- 左边长方形 -->
        <p class="rectangle"></p>
        <p class="text">源器</p>
      </div>
      <!-- 分类 -->
      <div class="type">
        <span class="type_text type_select">防具</span>
        <span class="type_text">模组</span>
      </div>
      <!-- 内容详情 -->
      <div class="details">
        <!-- 1 -->
        <div class="details_content">
          <div class="details_img">
            <img src="../assets/img/Wiki/arms-1.png" alt="" />
          </div>
          <div class="details_name">R-电磁刃</div>
        </div>
        <!-- 2 -->
        <div class="details_content">
          <div class="details_img">
            <img src="../assets/img/Wiki/arms-1.png" alt="" />
          </div>
          <div class="details_name">R-电磁刃</div>
        </div>
        <!-- 3 -->
        <div class="details_content">
          <div class="details_img">
            <img src="../assets/img/Wiki/arms-1.png" alt="" />
          </div>
          <div class="details_name">R-电磁刃</div>
        </div>
        <!-- 4 -->
        <div class="details_content">
          <div class="details_img">
            <img src="../assets/img/Wiki/arms-1.png" alt="" />
          </div>
          <div class="details_name">R-电磁刃</div>
        </div>
        <!-- 5 -->
        <div class="details_content">
          <div class="details_img">
            <img src="../assets/img/Wiki/arms-1.png" alt="" />
          </div>
          <div class="details_name">R-电磁刃</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import util from "../util/util";
import request from "../util/request";

export default {
  name: "Wiki",
  components: {},
  data() {
    return {
      //要跳转走的页面配置
      pageList: [
        {
          page: "ArmsList",
          name: "武器",
          detailShow: true,
          itf: "armsList",
          data: [],
          key: "armsList",
        },
        {
          page: "SourceList",
          name: "源器",
          detailShow: true,
          itf: "sourceList",
          data: [],
          key: "sourceList",
        },
        {
          page: "ThingList",
          name: "道具",
          detailShow: true,
          itf: "thingList",
          data: [],
          key: "thingList",
        },
        {
          page: "Cooking",
          name: "烹饪",
          detailShow: true,
          itf: "cookingList",
          data: [],
          key: "cookingList",
        },
        {
          page: "General",
          name: "成就",
          detailShow: true,
          itf: "achievementList",
          data: [],
          key: "achievementList",
        },
        {
          page: "General",
          name: "连携攻击",
          detailShow: true,
          itf: "chainAttackList",
          data: [],
          key: "chainAttackList",
        },
        {
          page: "General",
          name: "货币",
          detailShow: true,
          itf: "currencyList",
          data: [],
          key: "currencyList",
        },
        {
          page: "General",
          name: "日常任务",
          detailShow: true,
          itf: "dailyTasksList",
          data: [],
          key: "dailyTasksList",
        },
        {
          page: "General",
          name: "元素",
          detailShow: true,
          itf: "elementList",
          data: [],
          key: "elementList",
        },
        {
          page: "General",
          name: "装备",
          detailShow: true,
          itf: "equipmentList",
          data: [],
          key: "equipmentList",
        },
        {
          page: "General",
          name: "外观",
          detailShow: true,
          itf: "exteriorList",
          data: [],
          key: "exteriorList",
        },
        {
          page: "General",
          name: "好感度",
          detailShow: true,
          itf: "favorabilityList",
          data: [],
          key: "favorabilityList",
        },
        {
          page: "General",
          name: "引导任务",
          detailShow: true,
          itf: "guideTaskList",
          data: [],
          key: "guideTaskList",
        },
        {
          page: "General",
          name: "家园任务",
          detailShow: true,
          itf: "homeTaskList",
          data: [],
          key: "homeTaskList",
        },
        {
          page: "General",
          name: "主线剧情",
          detailShow: true,
          itf: "mainStoryList",
          data: [],
          key: "mainStoryList",
        },
        {
          page: "General",
          name: "艾达往事",
          detailShow: true,
          itf: "pastList",
          data: [],
          key: "pastList",
        },
        {
          page: "General",
          name: "悬赏任务",
          detailShow: true,
          itf: "rewardTaskList",
          data: [],
          key: "rewardTaskList",
        },
        {
          page: "General",
          name: "支线任务",
          detailShow: true,
          itf: "sideTaskList",
          data: [],
          key: "sideTaskList",
        },
        {
          page: "General",
          name: "源器材料",
          detailShow: true,
          itf: "sourceMaterialList",
          data: [],
          key: "sourceMaterialList",
        },
        {
          page: "General",
          name: "抑制器",
          detailShow: true,
          itf: "suppressorList",
          data: [],
          key: "suppressorList",
        },
        {
          page: "General",
          name: "公会",
          detailShow: true,
          itf: "unionList",
          data: [],
          key: "unionList",
        },
        {
          page: "General",
          name: "载具",
          detailShow: true,
          itf: "vehicleList",
          data: [],
          key: "vehicleList",
        },
        {
          page: "General",
          name: "武器材料",
          detailShow: true,
          itf: "weaponMaterialList",
          data: [],
          key: "weaponMaterialList",
        },
        {
          page: "General",
          name: "意志",
          detailShow: true,
          itf: "willList",
          data: [],
          key: "willList",
        },
      ],
      key: "",
    };
  },
  methods: {},
  mounted() {
    request.post("getList").then((e) => {
      console.log(e);
      for (let i = 0; i < this.pageList.length; i++) {
        this.pageList[i].data = e.data[this.pageList[i].key];
      }
    });

    // findPageItem = pageList
    //循环加载数据
    // for (let i = 0; i <script this.pageList.length; i++) {
    //   request.post(this.pageList[i].itf).then((e) => {
    //     this.pageList[i].data = e.data;
    //   });
    // }
  },
  activated() {
    this.key = this.$route.query.key;
  },
};
</script>
<style lang="less" scoped>
@import "../assets/less/public.less";
.Wiki {
  .el_attr_val(margin-left, 10);
  .el_attr_val(margin-right, 10);
//   font-family: "SourceHanSansCN_Medium";
}
.wikiContent {
  .el_attr_val(padding, 40);
  .el_attr_val(margin-top, 10);
  .el_attr_val(margin-bottom, 24);
  .el_attr_val(border-radius, 25);
  box-shadow: 0 0 10px rgb(117, 116, 116);
  background-color: #fff;
  // 标题
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .rectangle {
      background: #e4c866;
      .el_attr_val(width, 8);
      .el_attr_val(height, 45);
    }
    .text {
      .el_attr_val(text-indent, 23);
      line-height: 6vw;
      .el_attr_val(font-size, 45);
    //   font-family: "SourceHanSansCN_Bold";
      white-space: nowrap;
      font-weight: bold;
    }
    .title_icon {
      .el_attr_val(margin-left, 200);
      .el_attr_val(width, 20);
      transform: rotate(90deg);
      // 展开箭头样式
      &.title_iconSelect {
        transform: rotate(0deg);
      }
    }
  }
  // 分类
  .type {
    width: 100%;
    .type_text {
      display: inline-block;
      text-align: center;
      width: 50%;
      .el_attr_val(font-size, 28);
      .el_attr_val(padding-bottom, 17);
      .el_attr_val(margin-top, 25);
      font-weight: bold;
      border-bottom: 0.5vw solid #c0c0c0;
      // 选中样式
      &.type_select {
        border-bottom: 0.5vw solid #000;
      }
    }
  }
  // 内容详情
  .details {
    // .el_attr_val(max-height, 600);
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
    display: flex;
    height: auto;
    padding-bottom: 30px;
    flex-wrap: wrap;
    .details_content {
      display: flex;
      align-items: center;
      flex-direction: column;
      .el_attr_val(margin-left, 70);
      .el_attr_val(margin-top, 35);
      .el_attr_val(margin-bottom, 35);
      position: relative;
      &:nth-child(4n + 1) {
        margin-left: 0;
      }
      .details_img {
        .el_attr_val(width, 108);
        .el_attr_val(height, 120);
      }
      .details_name {
        // white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        // .el_attr_val(margin-top,7);
        .el_attr_val(font-size, 22.2);
        font-weight: bold;
        .el_attr_val(width, 110);
        text-align: center;
        position: absolute;
        top: 110%;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
}
</style>
