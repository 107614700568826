import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home.vue'
import Map from '../pages/Map.vue'
import Wiki from '../pages/Wiki.vue'
import ArmsList from '../pages/WikiDetail/ArmsList.vue'
import ThingList from '../pages/WikiDetail/ThingList.vue'
import SourceList from '../pages/WikiDetail/SourceList.vue'
import Cooking from '../pages/WikiDetail/Cooking.vue'
import General from '../pages/WikiDetail/General.vue'
import Classification from '../pages/classification.vue'
import FaceDtl from '../pages/FaceDtl.vue'
import Face from '../pages/Face.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/Map',
        name: 'Map',
        // component: () => import('../pages/WikiDetail/Map.vue')
        component: Map
    },
    {
        path: '/Wiki',
        name: 'Wiki',
        // component: () => import('../pages/WikiDetail/Map.vue')
        component: Wiki
    },
    {
        path: '/ArmsList',
        name: 'ArmsList',
        // component: () => import('../pages/WikiDetail/Map.vue')
        component: ArmsList
    },
    {
        path: '/ThingList',
        name: 'ThingList',
        // component: () => import('../pages/WikiDetail/Map.vue')
        component: ThingList
    },
    {
        path: '/SourceList',
        name: 'SourceList',
        // component: () => import('../pages/WikiDetail/Map.vue')
        component: SourceList
    },
    {
        path:'/Cooking',
        name:'Cooking',
        // component: () => import('../pages/WikiDetail/Cooking.vue')
        component:Cooking
    },
    {
        path:'/General',
        name:'General',
        // component: () => import('../pages/WikiDetail/Cooking.vue')
        component:General
    },
    {
        path:'/Classification',
        name:'Classification',
        // component: () => import('../pages/WikiDetail/Cooking.vue')
        component:Classification
    },
    {
        path:'/FaceDtl',
        name:'FaceDtl',
        // component: () => import('../pages/WikiDetail/Cooking.vue')
        component:FaceDtl
    },
    {
        path:'/Face',
        name:'Face',
        // component: () => import('../pages/WikiDetail/Cooking.vue')
        component:Face
    },
];

const router = new VueRouter({
    // mode: 'hash',
    mode: 'history',
    routes
})

router.afterEach((to, from) => {
    // 每次进入路由都会触发
    if (window._hmt) {
        console.log(to.path)
        if (to.path) {
            window._hmt.push(['_setAutoPageview', false]);
            window._hmt.push(['_trackPageview', to.path]); // 如果不是根路径，需要指定第二个参数的路径
        }
    }
});

export default router
