<template>
  <!-- 主页 -->
  <div class="Home">
    <div class="home_content">
      <div class="list" @click="toPage('Classification')">
        <img src="../assets/img/Home/01.png" alt="" />
      </div>
      <div class="list" @click="toPage('Face')">
        <img src="../assets/img/Home/03.png" alt="" />
      </div>
      <div class="list" @click="toPage('Map')">
        <img src="../assets/img/Home/02.png" alt="" />
      </div>
      <div class="list">
        <img src="../assets/img/Home/04.png" alt="" />
      </div>
      <div class="list">
        <img src="../assets/img/Home/05.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
@import "../assets/less/public.less";
.home_content {
  .el_attr_val(margin,10);
  .el_attr_val(padding-left,10);
  .el_attr_val(padding-right,10);
  .el_attr_val(padding-top,15);
  .el_attr_val(padding-bottom,15);
  .el_attr_val(border-radius,15);
  // box-shadow: 0 0 10px rgb(179, 178, 178);
  // font-family: "SourceHanSansCN_Medium";
}
.list {
  .el_attr_val(width,716);
  animation: listAn 0.5s;
}
@keyframes listAn {
  from {
    opacity: 0;
    transform: translateY(-100vw);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>